import React, { Component } from "react";

import Logo from "../../assets/logo.png";
import Input from "../../UI_Elements/Form/Input";

import "./sass/Login.scss";

import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//Assets
import { FaLinkedinIn } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import axios from "axios";

//Redux
import { compose } from "redux";
import { connect } from "react-redux";

import LOG_IN_USER from "../../inc/LOG_IN_USER";

import GoogleOAuth from "../../inc/GoogleOAuth";

import Response from "../../UI_Elements/Response";
import showResponse from "../../UI_Elements/Response/showResponse";

import Preloader from "../../UI_Elements/Preloader";

class Login extends Component {
  constructor() {
    super();

    this.email = React.createRef();
    this.password = React.createRef();

    this.LOGIN_USER = this.LOGIN_USER.bind(this);

    this.state = {
      GoogleOAuthState: 1,

      //Errors
      emailError: "",
      passwordError: "",

      //Load
      formSent: false,
    };
  }

  LOGIN_USER(e) {
    e.preventDefault();

    this.setState({ formSent: true });

    //Validate
    let email = this.email.current.state.inputValue;
    let password = this.password.current.state.inputValue;

    //Validations
    const emailIsValid = email.trim() != "";
    const passwordIsValid = password.trim() != "";

    if (!emailIsValid) {
      this.setState({ emailError: "Email field is required", formSent: false });
    } else this.setState({ emailError: "" });

    if (!passwordIsValid) {
      this.setState({
        passwordError: "Password field is required",
        formSent: false,
      });
    } else this.setState({ passwordError: "" });

    if (emailIsValid && passwordIsValid) {
      axios
        .post("/api/authenticate", { email, password })
        .then((res) => {
          this.setState({ formSent: false });
          LOG_IN_USER(res.data);

          this.props.history.push("/my-listings");
        })
        .catch((error) => {
          this.setState({ formSent: false });
          showResponse("loginError", error.response.data.error);
        });
    }
  }

  render() {
    const { GoogleOAuthState, emailError, passwordError, formSent } =
      this.state;

    if (GoogleOAuthState == 0) return <Preloader page />;

    return (
      <div id="Login">
        <Response
          id="loginError"
          type="error"
          title="Ops! Something went wrong"
          description=""
          btnText="Try again"
          btnAction="close"
        />

        <section>
          <div className="content">
            <Link className="back" to="/">
              <MdClose size="35" />
            </Link>

            <div>
              <Link to="/">
                <img width="220" src={Logo} alt="Mentory.me" />
              </Link>

              <form className="simple" onSubmit={this.LOGIN_USER}>
                <Input
                  id="loginEmail"
                  error={emailError}
                  ref={this.email}
                  placeholder="Email"
                />
                <Input
                  id="loginPassword"
                  type="password"
                  error={passwordError}
                  ref={this.password}
                  placeholder="Password"
                />

                {formSent ? (
                  <Preloader />
                ) : (
                  <button className="btn large trl login btnLogin">
                    Login
                  </button>
                )}

                <Link
                  className="primaryLink trl login forgotPassword"
                  to="/reset-password"
                >
                  Forgot your password?
                </Link>
              </form>

              <div className="footer">
                {/* 
                <p className="trl login orLoginWith">— Or login with —</p>
                  <GoogleOAuth
                  label="Sign in with Google"
                  startOAuth={() => this.setState({ GoogleOAuthState: 0 })}
                  success={() => this.props.history.push("/my-listings")}
                />
                  */}

                <p>
                  <span className="trl login dontHaveAnAccount">
                    Don’t have an account?
                  </span>{" "}
                  <Link className="primaryLink trl login signUp" to="/signup">
                    Sign Up
                  </Link>
                </p>
              </div>

              <p className="copy">
                <span className="trl login copyRight1">
                  By clicking sign up you agree to our
                </span>{" "}
                <a
                  className="primaryLink trl login copyRight4"
                  target="_blank"
                  href="https://leads.mentory.me/politica-privacidad/"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default compose(withRouter, connect(mapStateToProps))(Login);
